import { TextField, useSnackbarContext, AutocompleteSelect } from '@paddl/storybook';
import {
  initGoogleLocationApi,
  fetchLocationSuggestions
} from '@paddl/utils-js';
import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useMyProfile } from '../../pages/MyProfile/MyProfileContext';
import { ModalContext } from '../../pages/Profile/ModalContext';
import { EditModalWrapper } from '../EditModalWrapper';
import { ProfileLocationWrapper } from './EditProfileInformationModal.styles';

export const NEON_EditProfileInformationModal = () => {
  const [formState, setFormState] = useState({});

  const { openModal, closeModal } = useContext(ModalContext);
  const isOpen = openModal === 'NEON_edit-profile-information';
  const { showSnackbar } = useSnackbarContext();
  const [locationSuggestions, setLocationSuggestions] = useState([]);

  const { profile, update, isLoading } = useMyProfile();

  useEffect(() => {
    initGoogleLocationApi();
  }, []);

  useEffect(() => {
    const { title, fullName, location } = profile;
    setFormState({ title, fullName, location });
  }, [profile]);

  if (!isOpen) return null;
  const onSave = () => {
    update({ fullName: formState.fullName, title: formState.title, location: formState.location }).then(() => {
      showSnackbar({ message: 'Profile details successfully updated.', type: 'success' });
    }).catch(() => {
      showSnackbar({ message: 'Error updating profile details.', type: 'error' });
    }).finally(() => {
      closeModal();
    });
  };

  const handleLocations = (address) => {
    fetchLocationSuggestions(address, (locations) => {
      // if (userDetails.location !== address) {
      setLocationSuggestions(locations);
      // }
    });
  };

  return (
    <EditModalWrapper
      isLarge
      data-test-id="modal-wrapper"
      hasBrandedColour={false}
      isOpen={isOpen}
      setIsOpen={closeModal}
      title="Edit Profile Details"
      subtitle="Help employers and friends find your Profile."
      cancelButton={<Button onClick={closeModal} variant="text">Cancel</Button>}
      saveButton={<Button disabled={isLoading} variant="contained" onClick={onSave}>Save</Button>}

    >
      <TextField
        label="Full name"
        data-test-id="full-name"
        onChange={(value) => setFormState({ ...formState, fullName: value })}
        helperText="Your name will be used to identify your Profile."
        value={formState.fullName}
        disabled={isLoading}
      />
      <TextField
        label="Profile Title"
        data-test-id="profile-title"
        placeholder="e.g. Student at Educator"
        helperText="Your job title, your studies or get creative!"
        onChange={(value) => setFormState({ ...formState, title: value })}
        value={formState.title}
        disabled={isLoading}
      />
        <ProfileLocationWrapper>
        <AutocompleteSelect
          label="Location"
          action={(item) => {
            setFormState({ ...formState, location: item?.title });
          }}
          placeholder="e.g. Helsinki"
          helperText="Help employers and friends find you online"
          defaultValue={formState.location}
          options={locationSuggestions}
          getInputValue={(value) => handleLocations(value)}
        />
        </ProfileLocationWrapper>
    </EditModalWrapper>
  );
};
