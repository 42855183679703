import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { Button, Switch, useSnackbarContext } from '@paddl/storybook';
import { withDataSubmission } from '@paddl/utils-react';
import { urlStringForPathKey } from '@paddl/utils-js';
import { shape, func } from 'prop-types';
import {
  ToggleWrapper,
  FormLabel,
  SectionDivider,
  ButtonWrapper
} from './ProfileVisibilityForm.styles';
import { profileVisibilityFormValidator } from './validator';

export const ProfileVisibilityFormComp = ({
  profileVisibility,
  profileSettings: { id, urlSlug },
  updateProfileVisibility: {
    submit: patch
  }
}) => {
  const [profileVisibilityForm, setProfileVisibilityForm] = useState({
    master: null,
    aboutMe: null,
    currentLocation: null
  });

  const { showSnackbar } = useSnackbarContext();

  useEffect(() => {
    setProfileVisibilityForm(profileVisibility);
  }, [profileVisibility]);

  const submitProfileVisibility = async (body) => {
    try {
      await profileVisibilityFormValidator.validate(body);
      await patch(`${process.env.API_PREFIX_V2}/visibility-settings`, body);
      showSnackbar({ message: 'Profile visibility successfully updated.', type: 'success' });
    } catch (e) {
      console.error(e);
      showSnackbar({ message: 'Error updating profile visibility', type: 'error' });
    }
  };

  const submitAndUpdateStateOfProfileVisibility = (newState) => {
    setProfileVisibilityForm(newState);

    return submitProfileVisibility(newState);
  };

  const updateProfileVisibility = (update) => {
    const newState = { ...profileVisibilityForm, ...update };

    if (update?.master === false) {
      return submitAndUpdateStateOfProfileVisibility({
        ...newState,
        aboutMe: false,
        currentLocation: false
      });
    }
    if (update?.aboutMe === true || update?.currentLocation === true) {
      return submitAndUpdateStateOfProfileVisibility({
        ...newState,
        master: true
      });
    }

    return submitAndUpdateStateOfProfileVisibility(newState);
  };

  return (
    Object.keys(profileVisibility).length ? (
        <>
          <ToggleWrapper>
            <FormLabel bold>Show Profile Publicly</FormLabel>
            <Switch
              value={profileVisibilityForm.master}
              data-test-id="master-switch"
              onClick={(master) => updateProfileVisibility({ master })}
            />
          </ToggleWrapper>
          <SectionDivider />
          <FormLabel bold>Show Profile Sections</FormLabel>
          <ToggleWrapper>
            <FormLabel>About me</FormLabel>
            <Switch
              value={profileVisibilityForm.aboutMe}
              data-test-id="about-me-switch"
              onClick={(aboutMe) => updateProfileVisibility({ aboutMe })}
            />
          </ToggleWrapper>
          <ToggleWrapper>
            <FormLabel>Current location</FormLabel>
            <Switch
              value={profileVisibilityForm.currentLocation}
              data-test-id="current-location-switch"
              onClick={(currentLocation) =>
                updateProfileVisibility({ currentLocation })}
            />
          </ToggleWrapper>
          <SectionDivider />
          <ButtonWrapper>
            <Button
              fullWidth
              href={urlStringForPathKey('publicProfileById', {
                id: urlSlug || id
              })}
              target="_blank"
            >
              See your Public Profile
            </Button>
          </ButtonWrapper>
        </>
    ) : (
        <div>Loading settings...</div>
    )
  );
};

ProfileVisibilityFormComp.propTypes = {
  profileVisibility: shape({}),
  profileSettings: shape({}),
  updateProfileVisibility: shape({
    submit: func.isRequired,
    response: shape({}).isRequired
  }).isRequired
};

ProfileVisibilityFormComp.defaultProps = {
  profileVisibility: {},
  profileSettings: {}
};

export const ProfileVisibilityForm = compose(
  withDataSubmission({ propNameWrapper: 'updateProfileVisibility' })
)(ProfileVisibilityFormComp);
