import Slider from '@mui/material/Slider';
import {
  Icons
} from '@paddl/storybook';
import {
  func
} from 'prop-types';
import React from 'react';
import { SliderWrapper } from './EditProfilePictureModal.styles';

const { add: PlusIcon, subtract: MinusIcon } = Icons;

export const ZoomSlider = ({ setZoom }) => {
  return (
    <>
      <SliderWrapper>
        <MinusIcon className="icon" />
        <Slider
          data-test-id="slider"
          onChange={(event) => setZoom(Number(event.target.value))}
          defaultValue={1.2}
          step={0.1}
          min={1}
          max={2.5}
        />
        <PlusIcon className="icon" />
      </SliderWrapper>
      <em>Slide to zoom and drag your image to adjust cropping.</em>
    </>);
};

ZoomSlider.propTypes = {
  setZoom: func.isRequired
};
