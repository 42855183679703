import React, { useContext } from 'react';
import {
  number,
  bool,
  shape
} from 'prop-types';
import {
  EmptyPageFiller,
  EditableContentBlock,
  ProfileLinks,
  MarkDownSection,
  ExperienceAnalyticsLockedState,
  FeedFooter,
  InnerContentWrapper,
  Section,
  PageColumn, Column, SectionHeader
} from '@paddl/storybook';
import { useResource } from '@paddl/utils-react';
import { ProfileGraphs } from '../../ProfileGraphs';
import {
  EditableContentBlockWrapper
} from './Summary.styles';
import { ModalContext } from '../../../pages/Profile/ModalContext';

const ExperienceSection = ({ numberOfActivities, isProfileOwner, profileId }) => {
  if (numberOfActivities >= 5) {
    return (
    <Section>
      <SectionHeader>Experience</SectionHeader>
      <div>
        <ProfileGraphs
          profileId={profileId}
        />
      </div>
    </Section>);
  }

  if (isProfileOwner && numberOfActivities < 5) {
    return (
      <Section>
        <SectionHeader>Experience</SectionHeader>
        <div style={{ padding: '16px' }}>
          <ExperienceAnalyticsLockedState stepsCompleted={numberOfActivities} />
        </div>
      </Section>
    );
  }

  return null;
};

ExperienceSection.propTypes = {
  numberOfActivities: number.isRequired,
  isProfileOwner: bool.isRequired,
  profileId: number.isRequired
};

export const Summary = ({
  numberOfActivities,
  responseProfile,
  totalXp,
  isProfileOwner
}) => {
  const { setOpenModal } = useContext(ModalContext);

  const { data: profileLinks, isLoading: isProfileLinksLoading } = useResource(`${process.env.API_PREFIX_V2}/profile-links/${responseProfile.id}`);
  const numberOfProfileLinks = profileLinks ? Object.values(profileLinks).filter((value) => !!value).length : 0;

  const profileIsEmpty = (!isProfileOwner && responseProfile?.summary === null && numberOfProfileLinks === 0 && totalXp <= 0);

  if (profileIsEmpty) {
    return (
      <EmptyPageFiller
        data-test-id="no-summary"
        icon="graphStars"
        firstLine="This profile doesn't appear to have any public content."
        secondLine="They must be keeping their up-skilling secret!"
      />);
  }

  return (
    <InnerContentWrapper size="medium">
      <PageColumn>
        <Section>
          <Column style={{ gap: '16px' }}>
            {(isProfileOwner || responseProfile?.summary) &&
              <EditableContentBlock
                isEditable={isProfileOwner}
                triggerModal={() => setOpenModal('edit-profile-summary')}
                title="Add your About me"
                data-test-id="profile-summary"
                subtitle="Share a short bio to tell more of your story."
                heading={isProfileOwner ? 'About me' : `About ${(responseProfile?.fullName || '').split(' ')?.[0]}`}
                content={responseProfile?.summary ? <MarkDownSection markDown={responseProfile?.summary} /> : null}
              />}
            {!isProfileLinksLoading &&
              (isProfileOwner || numberOfProfileLinks > 0) && (
                <EditableContentBlockWrapper>
                  <EditableContentBlock
                    isEditable={isProfileOwner}
                    triggerModal={() => setOpenModal('edit-profile-links')}
                    title="Add Links"
                    subtitle="Share links to your website, portfolio or other profile."
                    data-test-id="profile-links"
                    heading="Links"
                    skeletonLoaderType="icon"
                    content={
                      numberOfProfileLinks > 0 ? (
                        <ProfileLinks links={profileLinks} />
                      ) : null
                    }
                  />
                </EditableContentBlockWrapper>
            )}
          </Column>
        </Section>
        <ExperienceSection numberOfActivities={numberOfActivities} isProfileOwner={isProfileOwner} profileId={responseProfile?.id} />

        <FeedFooter />
      </PageColumn>
    </InnerContentWrapper>
  );
};

Summary.propTypes = {
  responseProfile: shape({}).isRequired,
  numberOfActivities: number,
  totalXp: number,
  isProfileOwner: bool
};

Summary.defaultProps = {
  totalXp: 0,
  isProfileOwner: false,
  numberOfActivities: 0
};
