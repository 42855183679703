import React, { useState } from 'react';
import { compose } from 'recompose';
import {
  TextField,
  AutocompleteSelect,
  Datepicker,
  Button,
  useSnackbarContext
} from '@paddl/storybook';
import { withDataSubmission } from '@paddl/utils-react';
import { shape, func } from 'prop-types';
import {
  BottomInputsWrappers,
  InputWrapper,
  ButtonsWrapper,
  FieldWrapper
} from '../ProfileSettingsForms.styles';
import { personalDetailsFormValidator } from './validator';

const options = [
  {
    value: 'female',
    title: 'Female'
  },
  {
    value: 'male',
    title: 'Male'
  },
  {
    value: 'other',
    title: 'Other'
  }
];

const emptyForm = {
  fullName: '',
  dob: '',
  gender: ''
};

export const PersonalDetailsFormComp = ({
  profileSettings,
  updateProfileSettings: {
    submit: patch
  }
}) => {
  const [profileSettingsForm, setProfileSettingsForm] = useState(profileSettings || emptyForm);
  const { showSnackbar } = useSnackbarContext();

  const submitPersonalDetails =  async () => {
    try {
      await personalDetailsFormValidator.validate(profileSettingsForm);
      patch(`${process.env.API_PREFIX_V2}/profiles/settings`, profileSettingsForm,  'patch');
      showSnackbar({ message: 'Personal details successfully updated.', type: 'success' });
    } catch (e) {
      console.error(e);
      showSnackbar({ message: 'Error updating personal details', type: 'error' });
    }
  };

  if (!profileSettings) return <>Loading settings...</>;

  return (
        <>
          <FieldWrapper>
            <TextField
              label="Full Name"
              placeholder="Full Name"
              isControlled
              value={profileSettingsForm.fullName}
              onChange={(fullName) => setProfileSettingsForm({ ...profileSettingsForm, fullName })}
            />
          </FieldWrapper>
          <BottomInputsWrappers>
            <InputWrapper>
              <FieldWrapper>
                <Datepicker
                  label="Date of birth"
                  placeholder="dd/mm/yyyy"
                  helperText="Not required to share."
                  action={(dob) => setProfileSettingsForm({ ...profileSettingsForm, dob })}
                  defaultValue={profileSettings?.dob}
                />
              </FieldWrapper>
            </InputWrapper>
            <InputWrapper>
              <FieldWrapper>
                <AutocompleteSelect
                  label="Gender"
                  placeholder="Gender"
                  helperText="Not required to share."
                  action={({ value: gender }) => setProfileSettingsForm({ ...profileSettingsForm, gender })}
                  defaultValue={options.find(({ value }) => profileSettings?.gender === value)?.title}
                  options={options}
                />
              </FieldWrapper>
            </InputWrapper>
          </BottomInputsWrappers>
          <ButtonsWrapper>
            <Button
              data-test-id="cancel"
              color="white"
              variant="contained"
              onClick={() => setProfileSettingsForm(profileSettings || emptyForm)}
            >
              Cancel
            </Button>
            <Button
              data-test-id="submit"
              variant="contained"
              onClick={submitPersonalDetails}
            >
              Save Changes
            </Button>
          </ButtonsWrapper>
        </>
  );
};

PersonalDetailsFormComp.propTypes = {
  profileSettings: shape({}),
  updateProfileSettings: shape({
    submit: func.isRequired,
    response: shape({}).isRequired
  }).isRequired
};

PersonalDetailsFormComp.defaultProps = {
  profileSettings: undefined
};

export const PersonalDetailsForm = compose(
  withDataSubmission({ propNameWrapper: 'updateProfileSettings' })
)(PersonalDetailsFormComp);
