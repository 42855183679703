import {
  ModularPage
} from '@paddl/storybook';
import {
  getDecodedUserSessionCookie,
  hardRedirect,
  isSlug
} from '@paddl/utils-js';
import {
  useResource,
  useSubmitData,
  MemberAppNavigation_Populated
} from '@paddl/utils-react';
import React, { useEffect, useMemo, useState } from 'react';
import { number, shape, string } from 'prop-types';
import { ModalContext } from './ModalContext';
import { ProfilePageContent } from './ProfilePageContent';
import { ProfileSideContent } from './ProfileSideContent';
import { MyProfileProvider } from '../MyProfile/MyProfileContext';
import { MenuItems } from '../../components';
import { ShareProfileActionButton } from './ShareProfileActionButton';

export const LEGACY_Profile = ({
  match: {
    params: {
      profileId: profileIdOrSlug
    }
  }
}) => {
  const { data: profile, isLoading: isProfileLoading } = useResource(`${process.env.API_PREFIX_V2}/profiles/${profileIdOrSlug}?extra=1${isSlug(profileIdOrSlug) ? '&bySlug=1' : ''}`);

  useEffect(() => {
    if (profile?.errorMessage) {
      hardRedirect('notFound');
    }
  }, [profile]);

  if (isProfileLoading) return null;

  return <ProfileInnerWrapper profile={profile} />;
};

LEGACY_Profile.propTypes = {
  match: shape({
    params: shape({
      profileId: string
    }).isRequired
  }).isRequired
};

const ProfileInnerWrapper = ({ profile }) => {
  const { id } = profile;
  const { data: timeline } = useResource(`${process.env.API_PREFIX_V2}/timeline/${id}`);
  const { data: totalXp } = useResource(`${process.env.API_PREFIX_V2}/profiles/${id}/total-xp-number`);
  const { data: profileTitle } = useResource(`${process.env.API_PREFIX_V2}/profile-title/${id}`);

  const { submit } = useSubmitData();

  const isProfileOwner = useMemo(() => {
    return getDecodedUserSessionCookie()?.profile_id === `${profile.id}`;
  }, [profile.id]);

  useEffect(() => {
    submit(`${process.env.API_PREFIX_PROFILE_CLICK_COUNT}/view/${id}`, '*');
  }, [id, submit]);

  const [openModal, setOpenModal] = useState(null);

  const modalContext = useMemo(() => {
    return {
      openModal, setOpenModal, closeModal: () => setOpenModal(null)
    };
  }, [openModal, setOpenModal]);

  const extendedProfile = { ...profile, title: profileTitle?.title || '' };

  const pageActionItems = [
    <ShareProfileActionButton />
  ];

  if (isProfileOwner) {
    pageActionItems.unshift(<MenuItems
      key="edit-menu"
      data-test-id="profile-edit-menu"
      profile={profile}
    />);
  }

  return (
    <ModalContext.Provider value={modalContext}>
      <MyProfileProvider>
      <ModularPage
        navigationContent={<MemberAppNavigation_Populated currentPage="profile" />}
        pageName="profile"
        pageContent={<ProfilePageContent
          isProfileOwner={isProfileOwner}
          profile={extendedProfile}
          totalXp={totalXp}
          timeline={timeline}
        />}
        pageActionItems={pageActionItems}
        sidebarContent={<ProfileSideContent
          responseProfile={extendedProfile}
          timelineResponse={timeline}
          isProfileOwner={isProfileOwner}
        />}
      />
      </MyProfileProvider>
    </ModalContext.Provider>);
};

ProfileInnerWrapper.propTypes = {
  profile: shape({
    id: number
  }).isRequired
};
