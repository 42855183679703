import React, { useEffect, useState } from 'react';
import { ProfilesList, SidebarSection, SidebarSectionHeader } from '@paddl/storybook';
import { hardRedirect, isToggleEnabled } from '@paddl/utils-js';
import { compose } from 'recompose';
import {
  arrayOf,
  shape,
  func,
  number,
  string
} from 'prop-types';
import { useResource, withDataFetching } from '@paddl/utils-react';
import GroupSharpIcon from '@mui/icons-material/GroupSharp';

const PROFILES_IN_SUMMARY = 5;
const TOTAL_PROFILES = PROFILES_IN_SUMMARY * 2;
const NUMBER_OF_SKILLS_NEEDED_TO_SKILLS_MATCH = 10;

export const ProfileSuggestionsComp = ({
  profileIdOrSlug,
  totalSkills,
  topProfiles: {
    response: topProfilesResponse,
    fetch: fetchTopProfiles
  },
  topProfilesWithMatchingSkills: {
    response: topProfilesWithMatchingSkillsResponse,
    fetch: fetchTopProfilesWithMatchingSkills
  }
}) => {
  const [profiles, setProfiles] = useState(undefined);
  const { data: profileSuggestions_NEON, isLoading } = useResource(`${process.env.AT_WORK_API}/v1/similar-members/profile/${profileIdOrSlug}`, !isToggleEnabled('neon'));

  useEffect(() => {
    if (!isToggleEnabled('neon')) return;
    if (isLoading || !profileSuggestions_NEON?.profileSuggestions) return;
    setProfiles(profileSuggestions_NEON.profileSuggestions);
  }, [profileSuggestions_NEON, isLoading]);

  useEffect(() => {
    if (isToggleEnabled('neon')) return;
    const {
      profileSuggestionsWithMatchingSkills = []
    } = topProfilesWithMatchingSkillsResponse;
    const { profileSuggestions = [] } = topProfilesResponse;

    setProfiles(
      profileSuggestionsWithMatchingSkills.length >= TOTAL_PROFILES
        ? profileSuggestionsWithMatchingSkills
        : profileSuggestions
    );
  }, [topProfilesResponse, topProfilesWithMatchingSkillsResponse]);

  useEffect(() => {
    if (isToggleEnabled('neon')) return;
    const {
      profileSuggestionsWithMatchingSkills
    } = topProfilesWithMatchingSkillsResponse;

    if (
      profileSuggestionsWithMatchingSkills &&
      profileSuggestionsWithMatchingSkills?.length <= TOTAL_PROFILES
    ) {
      fetchTopProfiles(`${process.env.API_PREFIX_V2}/profile-suggestions`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topProfilesWithMatchingSkillsResponse]);

  useEffect(() => {
    if (isToggleEnabled('neon')) return;
    if (totalSkills >= NUMBER_OF_SKILLS_NEEDED_TO_SKILLS_MATCH) {
      fetchTopProfilesWithMatchingSkills(
        `${process.env.API_PREFIX_V2}/profile-suggestions-with-matching-skills`
      );
    }
    if (totalSkills !== undefined && totalSkills <= NUMBER_OF_SKILLS_NEEDED_TO_SKILLS_MATCH) {
      fetchTopProfiles(`${process.env.API_PREFIX_V2}/profile-suggestions`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalSkills]);

  return (
    <SidebarSection>
      <SidebarSectionHeader title="Similar Members" icon={<GroupSharpIcon />} />
    <ProfilesList
      onProfileClick={(profileId) => hardRedirect('profileById', { id: profileId })}
      profiles={profiles?.length ? profiles : undefined}
      profilesInSummary={PROFILES_IN_SUMMARY}
      totalProfiles={TOTAL_PROFILES}
    />
    </SidebarSection>
  );
};

ProfileSuggestionsComp.propTypes = {
  totalSkills: number,
  topProfiles: shape({
    response: shape({
      profileSuggestions: arrayOf(shape({}))
    }),
    fetch: func
  }).isRequired,
  topProfilesWithMatchingSkills: shape({
    response: shape({
      profileSuggestionsWithMatchingSkills: arrayOf(shape({}))
    }),
    fetch: func
  }).isRequired,
  profileIdOrSlug: string
};

ProfileSuggestionsComp.defaultProps = {
  totalSkills: undefined,
  profileIdOrSlug: null
};

export const ProfileSuggestions = compose(
  withDataFetching({ propNameWrapper: 'topProfiles' }),
  withDataFetching({ propNameWrapper: 'topProfilesWithMatchingSkills' })
)(ProfileSuggestionsComp);
