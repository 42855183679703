import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import {
  TextField, Button, Hyperlink, useSnackbarContext
} from '@paddl/storybook';
import { getDecodedUserSessionCookie } from '@paddl/utils-js';
import { withDataFetching, withDataSubmission } from '@paddl/utils-react';
import {
  shape,
  func,
  oneOfType,
  string,
  bool,
  arrayOf
} from 'prop-types';
import { UrlSlugList } from './components';
import { ButtonsWrapper } from '../ProfileSettingsForms.styles';
import { UrlSlugListHeadline, UseProfileIdWrapper } from './ProfileUrlForm.styles';
import { profileUrlFormValidator } from './validator';

export const ProfileUrlFormComp = ({
  profileSettings,
  getIsSlugAvailable: {
    submit: fetchIsSlugAvailable,
    response: isSlugAvailable,
    isLoading: isLoadingIsSlugAvailable
  },
  getProfileUrlSuggestions: {
    fetch: getProfileUrlSuggestions,
    response: profileUrlSuggestions
  },
  updateProfileSlug: {
    submit: submitProfileSlug
  }
}) => {
  const { profile_id: profileId } = getDecodedUserSessionCookie();
  const { showSnackbar } = useSnackbarContext();

  const [profileSettingsForm, setProfileSettingsForm] = useState({
    urlSlug: undefined
  });

  useEffect(() => {
    getProfileUrlSuggestions(`${process.env.API_PREFIX_V2}/slugs/suggestions`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setProfileSettingsForm(profileSettings);
  }, [profileSettings]);

  useEffect(() => {
    if (profileSettingsForm.urlSlug) {
      fetchIsSlugAvailable(`${process.env.API_PREFIX_V2}/slugs/available/${profileSettingsForm.urlSlug}`, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileSettingsForm]);

  const submitPersonalDetails = async () => {
    try {
      await profileUrlFormValidator.validate(profileSettingsForm);
      submitProfileSlug(
        `${process.env.API_PREFIX_V2}/slugs?slug=${profileSettingsForm.urlSlug}`,
        {}
      );
      showSnackbar({ message: 'Profile URL successfully updated.', type: 'success' });
    } catch (error) {
      console.error(error);
      showSnackbar({ message: 'Error updating profile URL', type: 'error' });
    }
  };

  const {
    urlSlug: currentUrlSlug = ''
  } = profileSettings;

  const {
    urlSlug: newUrlSlug
  } = profileSettingsForm;

  return (
    Object.keys(profileSettings).length ? (
        <>
          <TextField
            label="Profile URL"
            placeholder="example_name"
            isControlled
            value={
              newUrlSlug !== undefined
                ? newUrlSlug
                : currentUrlSlug
            }
            startAdornment="www.paddl.com/xp/"
            hasError={
              !isLoadingIsSlugAvailable &&
              currentUrlSlug !== newUrlSlug &&
              isSlugAvailable === false
            }
            errorText="slug is not available"
            helperText="Max of 40 characters."
            onChange={(urlSlug) =>
              setProfileSettingsForm({ ...profileSettingsForm, urlSlug })}
          />
          <UseProfileIdWrapper>
            <Hyperlink
              onClick={() =>
                setProfileSettingsForm({
                  ...profileSettingsForm,
                  urlSlug: profileId
                })}
            >
              Use your Profile ID: <strong>{profileId}</strong>
            </Hyperlink>
          </UseProfileIdWrapper>
          <UrlSlugListHeadline>Suggestions</UrlSlugListHeadline>
          <UrlSlugList
            slugs={profileUrlSuggestions?.length ? profileUrlSuggestions : []}
            onClick={(urlSlug) => setProfileSettingsForm({ ...profileSettingsForm, urlSlug })}
          />
          <ButtonsWrapper>
            <Button
              data-test-id="cancel"
              color="white"
              variant="contained"
              onClick={() => setProfileSettingsForm(profileSettings)}
            >
              Cancel
            </Button>
            <Button
              data-test-id="submit"
              variant="contained"
              onClick={submitPersonalDetails}
              disabled={isSlugAvailable !== true}
            >
              Save Changes
            </Button>
          </ButtonsWrapper>
        </>
    ) : (
        <div>Loading settings...</div>
    )
  );
};

ProfileUrlFormComp.propTypes = {
  updateProfileSlug: shape({
    submit: func.isRequired,
    response: shape({}).isRequired
  }).isRequired,
  profileSettings: shape({}),
  getProfileUrlSuggestions: shape({
    response: oneOfType([shape({}), arrayOf(string)]),
    fetch: func
  }).isRequired,
  getIsSlugAvailable: shape({
    response: oneOfType([bool, shape({})]),
    fetch: func
  }).isRequired
};

ProfileUrlFormComp.defaultProps = {
  profileSettings: {}
};

export const ProfileUrlForm = compose(
  withDataSubmission({ propNameWrapper: 'updateProfileSlug' }),
  withDataFetching({ propNameWrapper: 'getProfileSettings' }),
  withDataFetching({ propNameWrapper: 'getProfileUrlSuggestions' }),
  withDataSubmission({ propNameWrapper: 'getIsSlugAvailable' })
)(ProfileUrlFormComp);
