import {
  FileSelect,
  Icons,
  ImageEditor,
  useSnackbarContext
} from '@paddl/storybook';
import { put } from '@vercel/blob';
import {
  func,
  string,
  bool,
  number
} from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import { useMyProfile } from '../../pages/MyProfile/MyProfileContext';
import { ModalContext } from '../../pages/Profile/ModalContext';
import { EditModalWrapper } from '../EditModalWrapper';
import { ZoomSlider } from './ZoomSlider';

const { camera: CameraIcon } = Icons;

const EditProfilePictureModalComp = ({
  oldAvatarUrl, updateProfile, isLoading, profileId
}) => {
  const { openModal, closeModal } = useContext(ModalContext);
  const [originalFile, setOriginalFile] = useState();
  const [selectedAvatarUrl, setSelectedAvatarUrl] = useState(oldAvatarUrl);
  const [resizedFile, setResizedFile] = useState(undefined);
  const isOpen = openModal === 'edit-profile-picture';
  const { showSnackbar } = useSnackbarContext();

  const [isDirty, setIsDirty] = useState(false);
  const [zoom, setZoom] = useState(1.2);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSave = useCallback(() => {
    const saveAvatar = async () => {
      const blob = (resizedFile?.blob || originalFile);
      if (blob) {
        const { url } = await put(`avatars/${profileId}/${originalFile.name}`, blob, {
          access: 'public'
        });
        await updateProfile({ avatarUrl: url });
        setSelectedAvatarUrl(url);
      }
    };

    setIsSubmitting(true);
    saveAvatar().then(() => {
      showSnackbar({ message: 'Profile avatar successfully updated.', type: 'success' });
      setIsDirty(false);
    }).catch(() => {
      showSnackbar({ message: 'Error updating profile avatar.', type: 'error' });
    }).finally(() => {
      setIsSubmitting(false);
      closeModal();
    });
  }, [closeModal, originalFile, profileId, resizedFile?.blob, showSnackbar, updateProfile]);

  const onRemove = useCallback(() => {
    setIsDirty(false);
    setOriginalFile(undefined);
    setSelectedAvatarUrl(undefined);
    setResizedFile(undefined);
  }, []);

  return (
  <EditModalWrapper
    isOpen={isOpen}
    setIsOpen={closeModal}
    title="Edit Profile Picture"
    subtitle="Help employers and friends identify you."
    cancelButton={<Button onClick={closeModal} variant="text">Cancel</Button>}
    saveButton={<Button disabled={!isDirty || isSubmitting || isLoading} variant="contained" onClick={onSave}>Save</Button>}
  >
    {!(originalFile || selectedAvatarUrl) &&
      <FileSelect
        callback={(file) => {
          setOriginalFile(file);
          setIsDirty(true);
        }}
        acceptTypes="image/*"
        maxFileSize={10000000}
        maxFileNumber={1}
        isAvatarStyle
      >
      <CameraIcon />
      </FileSelect>}
    {(originalFile || selectedAvatarUrl) &&
      <>
      <ImageEditor
        callback={(result) => setResizedFile(result)}
        disabled={!isDirty}
        imageUrl={originalFile || selectedAvatarUrl}
        zoom={zoom}
      />
        <Button variant="text" onClick={onRemove}>Remove</Button>
        {isDirty && <ZoomSlider setZoom={setZoom} />}
      </>}
  </EditModalWrapper>
  );
};

EditProfilePictureModalComp.defaultProps = {
  oldAvatarUrl: undefined,
  isLoading: false,
  profileId: null
};

EditProfilePictureModalComp.propTypes = {
  oldAvatarUrl: string,
  updateProfile: func.isRequired,
  isLoading: bool,
  profileId: number
};

export const NEON_EditProfilePictureModal = () => {
  const { isLoading, profile, update } = useMyProfile();

  return (<EditProfilePictureModalComp profileId={profile?.profileId} isLoading={isLoading} updateProfile={update} oldAvatarUrl={profile?.avatarUrl} />);
};
