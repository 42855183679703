import React, { useState, useEffect, useContext } from 'react';
import { compose } from 'recompose';
import {
  func,
  string,
  shape
} from 'prop-types';
import {
  trackEvent,
  trackIdentity,
  getDecodedUserSessionCookie,
  fetchLocationSuggestions,
  initGoogleLocationApi
} from '@paddl/utils-js';
import { TextField, Button, AutocompleteSelect } from '@paddl/storybook';
import { withDataSubmission } from '@paddl/utils-react';
import { ProfileLocationWrapper } from './EditProfileInformationModal.styles';
import { EditModalWrapper } from '../EditModalWrapper';
import { ModalContext } from '../../pages/Profile/ModalContext';

export const EditProfileInformationModalComp = ({
  title,
  location,
  fullName,
  setProfileTitle: {
    submit: setProfileTitle
  },
  setProfileFullName: {
    submit: setProfileFullName
  },
  setProfileLocation: {
    submit: setProfileLocation
  }
}) => {
  const { profile_id: profileId } = getDecodedUserSessionCookie();
  const [formState, setFormState] = useState({ isPristine: true });
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [userDetails, setUserDetails] = useState({ title, fullName, location });

  const { openModal, closeModal } = useContext(ModalContext);
  const isOpen = openModal === 'edit-profile-information';

  useEffect(() => {
    initGoogleLocationApi();
  }, []);

  useEffect(() => {
    setFormState({
      isPristine: JSON.stringify(userDetails) === JSON.stringify({ title, fullName, location })
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const submitProfileData = () => {
    Promise.all([
      userDetails.title !== title
        ? setProfileTitle(`${process.env.API_PREFIX_V2}/profile-title`, {
          title: userDetails.title
        }).then(() => {
          trackEvent('Updated profile title');
        })
        : Promise.resolve(true),
      userDetails.fullName !== fullName
        ? setProfileFullName(`${process.env.API_PREFIX_V2}/profiles/${profileId}/full-name`, {
          fullName: userDetails.fullName
        }).then(() => {
          trackEvent('Updated profile fullName');
        }) : Promise.resolve(true),
      userDetails.location?.placeId !== location.placeId
        ? setProfileLocation(`${process.env.API_PREFIX_V2}/profiles/${profileId}/location`, {
          location: {
            placeId: userDetails.location.value,
            originalText: userDetails.location.title
          }
        }).then(() => {
          trackEvent('Updated profile location');
        }) : Promise.resolve(true)
    ]).then(() => {
      trackIdentity(profileId, userDetails);
      window.location.reload();
    });
  };

  const handleLocations = (address = '') => {
    fetchLocationSuggestions(address, (locations) => {
      if (userDetails.location !== address) {
        setLocationSuggestions(locations);
      }
    });
  };

  const renderCancelButton = () => (
    <Button
      onClick={closeModal}
      variant="contained"
      color="white"
      data-test-id="cancel-button"
    >
      Cancel
    </Button>
  );

  const renderSaveButton = () =>  (
    <Button
      onClick={() => submitProfileData()}
      variant="contained"
      data-test-id="confirm-button"
      disabled={formState.isPristine}
    >
      Confirm
    </Button>
  );

  return (
    <EditModalWrapper
      isLarge
      data-test-id="modal-wrapper"
      hasBrandedColour={false}
      isOpen={isOpen}
      setIsOpen={closeModal}
      title="Edit Profile Details"
      subtitle="Help employers and friends find your Profile."
      cancelButton={renderCancelButton()}
      saveButton={renderSaveButton()}
    >
      <TextField
        label="Full name"
        data-test-id="full-name"
        onChange={(value) => setUserDetails({ ...userDetails, fullName: value })}
        helperText="Your name will be used to identify your Profile."
        defaultValue={fullName}
      />
      <TextField
        label="Profile Title"
        data-test-id="profile-title"
        placeholder="e.g. Student at Educator"
        helperText="Your job title, your studies or get creative!"
        onChange={(value) => setUserDetails({ ...userDetails, title: value })}
        defaultValue={title}
      />
      <ProfileLocationWrapper>
        <AutocompleteSelect
          label="Location"
          action={(item) => {
            const selectedLocation = item || { value: '', title: '' };
            setUserDetails({
              ...userDetails,
              location: selectedLocation
            });
          }}
          placeholder="e.g. Helsinki"
          helperText="Help employers and friends find you online"
          defaultValue={location?.originalText}
          options={locationSuggestions}
          getInputValue={(value) => handleLocations(value)}
        />
      </ProfileLocationWrapper>
    </EditModalWrapper>
  );
};

EditProfileInformationModalComp.propTypes = {
  setProfileTitle: shape({
    submit: func.isRequired
  }).isRequired,
  setProfileFullName: shape({
    submit: func.isRequired
  }).isRequired,
  setProfileLocation: shape({
    submit: func.isRequired
  }).isRequired,
  title: string,
  location: shape({}),
  fullName: string
};

EditProfileInformationModalComp.defaultProps = {
  title: undefined,
  location: undefined,
  fullName: undefined
};

export const EditProfileInformationModal = compose(
  withDataSubmission({ propNameWrapper: 'setProfileTitle' }),
  withDataSubmission({ propNameWrapper: 'setProfileFullName' }),
  withDataSubmission({ propNameWrapper: 'setProfileLocation' })
)(EditProfileInformationModalComp);
