import React from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { func, bool, string } from 'prop-types';
import { Button } from '@paddl/storybook';

export const SaveButton = ({
  s3UploadTokenSubmit,
  profileId,
  selectedBannerId,
  isDirty
}) => {
  const { getAccessTokenSilently } = useAuth0();

  return (
    <Button
      onClick={async () => {
        if (!selectedBannerId) {
          // save custom image
          s3UploadTokenSubmit(
            `${process.env.API_PREFIX_V2}/profile-upload-token`,
            profileId
          );
        } else {
          const { id_token } = await getAccessTokenSilently({
            detailedResponse: true,
            timeoutInSeconds: 15
          });

          try {
            axios
              .post(
                `${process.env.API_PREFIX_V2}/banners`,
                { id: selectedBannerId },
                {
                  headers: {
                    Authorization: `Bearer ${id_token}`,
                    'X-profile-ID': profileId,
                    'Use-Auth0': true
                  }
                }
              )
              .then(() => {
                window.location.reload();
                // TODO: display snackbar with SUCCESS message after page reload
              });
          } catch (err) {
            // TODO: display snackbar with ERROR message
            // eslint-disable-next-line
            console.error(err);
          }
        }
      }}
      variant="contained"
      data-test-id="confirm-button"
      disabled={!isDirty}
    >
      Save
    </Button>
  );
};

SaveButton.propTypes = {
  s3UploadTokenSubmit: func.isRequired,
  profileId: string.isRequired,
  isDirty: bool.isRequired,
  selectedBannerId: string
};

SaveButton.defaultProps = {
  selectedBannerId: null
};
