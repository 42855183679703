import styled from '@emotion/styled';
import { Colors, Fonts } from '@paddl/storybook';

const {
  grey_lighter,
  sec_red_dark
} = Colors;

const { body } = Fonts;

export const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  [class^='Sliderstyles__SliderWrapper'] {
    width: 100%;
    margin: 0 24px;

    .MuiSlider-root {
      margin-bottom: 0;
    }
  }

  svg {
    width: 20px;
    fill: ${grey_lighter};
  }
`;

export const RemoveButton = styled.button`
  display: block;
  border: none;
  margin: 12px auto;
  background: transparent;
  cursor: pointer;
  ${body};
  color: ${sec_red_dark};

  &:hover {
    text-decoration: underline;
  }
`;
