import React, { useState, useContext } from 'react';
import axios from 'axios';
import {
  getDecodedUserSessionCookie,
  getCdnUrl,
  getFormData,
  trackIdentity,
  trackEvent
} from '@paddl/utils-js';
import { compose } from 'recompose';
import {
  Button,
  FileSelect,
  ImageEditor,
  Icons
} from '@paddl/storybook';
import {
  string
} from 'prop-types';
import { withDataSubmission, useSubmitData } from '@paddl/utils-react';
import Slider from '@mui/material/Slider';
import { EditModalWrapper } from '../EditModalWrapper';
import { SliderWrapper, RemoveButton } from './EditProfilePictureModal.styles';
import { ModalContext } from '../../pages/Profile/ModalContext';

const { camera: CameraIcon, add: PlusIcon, subtract: MinusIcon } = Icons;

const slider = (setZoom) => (
  <>
    <SliderWrapper>
      <MinusIcon className="icon" />
      <Slider
        data-test-id="slider"
        onChange={(event) => setZoom(event.target.value)}
        defaultValue={1.2}
        step={0.1}
        min={1}
        max={2.5}
      />
      <PlusIcon className="icon" />
    </SliderWrapper>
    <em>Slide to zoom and drag your image to adjust cropping.</em>
  </>
);

const removeButton = (setIsDirty, setOriginalFile) => (
  <RemoveButton
    data-test-id="remove-button"
    role="button"
    onClick={() => {
      setIsDirty(false);
      setOriginalFile(undefined);
    }}
  >
    Remove
  </RemoveButton>
);

const imageEditor = (setResizedFile, isDirty, originalFile, zoom) => (
  <ImageEditor
    callback={(result) => setResizedFile(result)}
    disabled={!isDirty}
    imageUrl={originalFile}
    zoom={Number(zoom)}
  />
);

const fileSelect = (setOriginalFile, setIsDirty) => (
  <FileSelect
    callback={(file) => {
      setOriginalFile(file);
      setIsDirty(true);
    }}
    isCallbackTypeBase64={false}
    acceptTypes="image/*"
    maxFileSize={10000000}
    maxFileNumber={1}
    isDisabled={false}
    isAvatarStyle
  >
    <CameraIcon />
  </FileSelect>
);

export const EditProfilePictureModalComp = ({
  avatarUrl: oldAvatarUrl
}) => {
  const { openModal, closeModal } = useContext(ModalContext);
  const [originalFile, setOriginalFile] = useState(oldAvatarUrl);
  const [resizedFile, setResizedFile] = useState(undefined);
  const isOpen = openModal === 'edit-profile-picture';

  const [isDirty, setIsDirty] = useState(false);
  const [zoom, setZoom] = useState(1.2);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { submit } = useSubmitData();

  const fileReadUrl = process.env.FILE_READ_URL;

  const { profile_id: profileId } = getDecodedUserSessionCookie();

  const uploadImageToS3 = async (s3UploadToken = {}) => {
    const endpoint = `${process.env.FILE_UPLOAD_ENDPOINT}/`;
    const originalImageData = getFormData(
      s3UploadToken,
      originalFile,
      'original'
    );
    const resizedImageData = getFormData(
      s3UploadToken,
      resizedFile?.blob || originalFile,
      'resized'
    );

    const [originalAvatarUrl, avatarUrl]  = await Promise
      .all([
        axios.post(endpoint, originalImageData).then(({ data }) => getCdnUrl(fileReadUrl, data)),
        axios.post(endpoint, resizedImageData).then(({ data }) => getCdnUrl(fileReadUrl, data))
      ]);

    return { originalAvatarUrl, avatarUrl };
  };

  const onSave = async () => {
    setIsSubmitting(true);
    try {
      const s3UploadToken = await submit(
        `${process.env.API_PREFIX_V2}/profile-upload-token`,
        profileId
      );
      const imageS3Urls = await uploadImageToS3(s3UploadToken);
      await submit(
        `${process.env.API_PREFIX_V2}/profiles/${profileId}/avatar-url`,
        imageS3Urls,
        'patch');
      trackEvent('Updated profile image');

      trackIdentity(profileId, {
        profilePictureUrl: imageS3Urls.originalAvatarUrl
      });
      window.location.reload();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
  <EditModalWrapper
    data-test-id="modal-wrapper"
    hasBrandedColour={false}
    isOpen={isOpen}
    setIsOpen={closeModal}
    title="Edit Profile Picture"
    subtitle="Help employers and friends identify you."
    cancelButton={<Button
      onClick={closeModal}
      variant="contained"
      color="white"
      data-test-id="cancel-button"
    >
      Cancel
                  </Button>}
    saveButton={<Button
      onClick={onSave}
      variant="contained"
      data-test-id="confirm-button"
      disabled={!isDirty || isSubmitting}
    >
      Save
                </Button>}
  >
    {!originalFile
      ? fileSelect(setOriginalFile, setIsDirty)
      : imageEditor(setResizedFile, isDirty, originalFile, zoom)}
    {originalFile ? (
      <>
        {removeButton(setIsDirty, setOriginalFile)}
        {isDirty ? slider(setZoom) : null}
      </>
    ) : null}
  </EditModalWrapper>
  );
};

EditProfilePictureModalComp.propTypes = {
  avatarUrl: string.isRequired
};

export const EditProfilePictureModal = compose(
  withDataSubmission({ propNameWrapper: 'getS3UploadToken' })
)(EditProfilePictureModalComp);
