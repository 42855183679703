import React, { useEffect } from 'react';
import { func, bool, shape } from 'prop-types';
import { getDecodedUserSessionCookie, isToggleEnabled } from '@paddl/utils-js';
import { compose } from 'recompose';
import {
  SettingsSectionFormWrapper,
  Icons,
  FeedFooter,
  PageColumn,
  Section,
  PageHeader,
  SectionHeader,
  InnerContentWrapper,
  MainContentWrapper,
  ModularPage,
  SnackbarProvider,
  SnackbarFeedback_V2
} from '@paddl/storybook';
import {
  useResource, withDataFetching, MemberAppNavigation_Populated, MemberAppNavigation_Populated_NEON
} from '@paddl/utils-react';
import {
  PersonalDetailsForm,
  ContactDetailsForm,
  ProfileUrlForm,
  ProfileVisibilityForm
} from '../../components';
import { NEON_ProfileVisibilityForm } from '../../components/ProfileSettingsForms/ProfileVisibilityForm/NEON_ProfileVisibilityForm';
import { NEON_PersonalDetailsForm } from '../../components/ProfileSettingsForms/PersonalDetailsForm/NEON_PersonalDetailsForm';
import { MyProfileProvider } from '../MyProfile/MyProfileContext';
import { ProfileUrlForm_NEON } from '../../components/ProfileSettingsForms/ProfileUrlForm/ProfileUrlForm_NEON';

const {
  user: User,
  chat: Chat,
  shareLink: ShareLink,
  view: View
} = Icons;

export const ProfileSettingsComp = ({
  getProfileSettings,
  getProfile: {
    fetch: fetchProfile,
    response: responseProfile
  },
  getProfileVisibility: {
    fetch: fetchProfileVisibility,
    response: responseProfileVisibility
  }
}) => {
  const { profile_id: profileId } = getDecodedUserSessionCookie();

  const { data: NEON_profileSettingsData, isLoading } = useResource(`${process.env.API_PREFIX_AT_WORK}/my-profile/settings`);

  useEffect(() => {
    if (profileId) {
      getProfileSettings.fetch(
        `${process.env.API_PREFIX_V2}/profiles/settings/${profileId}`
      );
      fetchProfile(
        `${process.env.API_PREFIX_V2}/profiles/${profileId}`
      );
      fetchProfileVisibility(
        `${process.env.API_PREFIX_V2}/visibility-settings`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MyProfileProvider>
    <MainContentWrapper colorVariant="dark">
    <InnerContentWrapper size="medium">
      <PageColumn>
      <PageHeader heading="Account Settings" tagline="Select a section below to manage how Paddl works for you." />
      <Section>
      <SectionHeader style={{ marginTop: '24px', marginBottom: '16px' }}>Your Account</SectionHeader>
      <SettingsSectionFormWrapper
        icon={<User />}
        title="Personal Details"
        subTitle="Full name, date of birth and more..."
        // eslint-disable-next-line max-len
        description="Providing this information helps us personalise your Paddl experience. Only your name will be displayed on your profile, visible to others in the Paddl community. We keep the rest secret."
      >
        {isToggleEnabled('neon') && <NEON_PersonalDetailsForm />}
        {!isToggleEnabled('neon') &&  getProfileSettings.isSuccess && <PersonalDetailsForm profileSettings={getProfileSettings.response} /> }
      </SettingsSectionFormWrapper>
      <SettingsSectionFormWrapper
        icon={<Chat />}
        title="Contact Details"
        subTitle="Email, phone and more..."
        // eslint-disable-next-line max-len
        description="Your contact details help Members get in touch, verify your account and keep you up to date with important notifications."
      >
        { getProfileSettings.isSuccess && <ContactDetailsForm profileSettings={getProfileSettings.response} /> }
      </SettingsSectionFormWrapper>
      </Section>
      <Section>
      <SectionHeader>Preferences</SectionHeader>
      <SettingsSectionFormWrapper
        icon={<ShareLink />}
        title="Profile URL"
        subTitle="Personalise your Paddl Profile link address."
        // eslint-disable-next-line max-len
        description="Set and reserve your unique Paddl URL for a personalised and professional link to your Profile and improved ranking on search engines."
      >
        {isToggleEnabled('neon') ? <ProfileUrlForm_NEON /> : <ProfileUrlForm profileSettings={responseProfile} />}
      </SettingsSectionFormWrapper>
      <SettingsSectionFormWrapper
        icon={<View />}
        title="Profile Visibility"
        subTitle="Select what you share publicly."
        // eslint-disable-next-line max-len
        description="Use the toggles to select which information you share publicly. Public profiles will also show up in web search results."
      >
        {(isToggleEnabled('neon'))
          ? (!isLoading && <NEON_ProfileVisibilityForm profileSettings={NEON_profileSettingsData.settings} />)
          : <ProfileVisibilityForm
              profileSettings={responseProfile}
              profileVisibility={responseProfileVisibility}
          />}

      </SettingsSectionFormWrapper>
      </Section>
      <SnackbarFeedback_V2 />
      <FeedFooter />
      </PageColumn>
    </InnerContentWrapper>
    </MainContentWrapper>
    </MyProfileProvider>
  );
};

ProfileSettingsComp.propTypes = {
  getProfileSettings: shape({
    fetch: func.isRequired,
    isSuccess: bool.isRequired,
    response: shape({}).isRequired
  }).isRequired,
  getProfile: shape({
    fetch: func.isRequired,
    isSuccess: bool.isRequired,
    response: shape({}).isRequired
  }).isRequired,
  getProfileVisibility: shape({
    fetch: func.isRequired,
    isSuccess: bool.isRequired,
    response: shape({}).isRequired
  }).isRequired
};

const _ProfileSettings = compose(
  withDataFetching({ propNameWrapper: 'getProfileSettings' }),
  withDataFetching({ propNameWrapper: 'getProfile' }),
  withDataFetching({ propNameWrapper: 'getProfileVisibility' })
)(ProfileSettingsComp);

export const ProfileSettings = () => (
<SnackbarProvider>
<ModularPage
  navigationContent={isToggleEnabled('neon')
    ? <MemberAppNavigation_Populated_NEON currentPage="settings" />
    : <MemberAppNavigation_Populated currentPage="settings" />}
  pageName="settings"
  pageContent={<_ProfileSettings />}

/>
</SnackbarProvider>);
