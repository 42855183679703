import React from 'react';
import { useFeatureToggles } from '@paddl/utils-react';
import { shape } from 'prop-types';
import { NEON_Profile } from './NEON_Profile';
import { LEGACY_Profile } from './LEGACY_Profile';

export const Profile = ({
  match
}) => {
  const { isInitialised, isToggleEnabled } = useFeatureToggles();
  if (!isInitialised) return null;

  if (isToggleEnabled('neon')) return <NEON_Profile match={match} />;

  return <LEGACY_Profile match={match} />;
};

Profile.propTypes = {
  match: shape({}).isRequired
};
