/* eslint-disable react/prop-types */
import {
  getSkillCountByTimeline,
  urlStringForPathKey
} from '@paddl/utils-js';
import React from 'react';
import {
  ShareLink, SidebarSection, SidebarSectionHeader, Row, Body
} from '@paddl/storybook';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import { useResource } from '@paddl/utils-react';
import {
  ProfileStrength,
  ProfileSuggestions,
  ProfileViews,
  SideContentLinks
} from '../../components';

// TODO: remove once neon migration is complete
export const ProfileSideContent = ({
  responseProfile, timelineResponse, isProfileOwner
}) => {
  const { data: profileViews } = useResource(`${process.env.API_PREFIX_PROFILE_CLICK_COUNT}/view/${responseProfile.id}`);
  const { total_views, trend_percentage, trend_direction } = profileViews || { total_views: 0, trend_percentage: 0, trend_direction: 'sideways' };
  const shareLink = urlStringForPathKey('publicProfileById', {
    id: responseProfile?.urlSlug || responseProfile.profileId
  });

  return (<>
        {isProfileOwner &&
        <>
          <SidebarSection>
            <SidebarSectionHeader
              icon={<VisibilitySharpIcon />}
              title="Profile Views"
            />
              <Row style={{ gap: '32px' }}>
                <Body>Share your Profile with Employers and on social to grow your exposure.</Body>
                <ProfileViews
                  totalViews={total_views}
                  trendPercentage={trend_percentage}
                  trendDirection={trend_direction}
                />
              </Row>
            <ShareLink url={shareLink} />
            <SideContentLinks shareLink={shareLink} />
          </SidebarSection>
            <ProfileStrength
              timeline={timelineResponse?.length ? timelineResponse : []}
              profile={responseProfile}
            />
        </>}
        <ProfileSuggestions
          totalSkills={
            timelineResponse
              ? getSkillCountByTimeline(timelineResponse)
              : undefined
          }
        />
          </>);
};
