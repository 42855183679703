import React, { useContext } from 'react';
import {
  bool,
  string,
  node
} from 'prop-types';
import { Modal } from '@paddl/storybook';
import {
  ModalContentWrapper,
  TitleWrapper,
  SubtitleWrapper,
  ContentWrapper,
  FooterWrapper
} from './EditModalWrapper.styles';
import { ModalContext } from '../../pages/Profile/ModalContext';

export const EditModalWrapper = ({
  isOpen,
  title,
  subtitle,
  cancelButton,
  saveButton,
  isLarge,
  children
}) => {
  const { closeModal } = useContext(ModalContext);

  return (
  <Modal
    hasBrandedColour={false}
    isOpen={isOpen}
    handleExit={closeModal}
    isLarge={isLarge}
  >
    <ModalContentWrapper>
    <TitleWrapper>
      {title}
    </TitleWrapper>
    <SubtitleWrapper>
      {subtitle}
    </SubtitleWrapper>
    <ContentWrapper>
      {children}
    </ContentWrapper>
    <FooterWrapper>
      {cancelButton}
      {saveButton}
    </FooterWrapper>
    </ModalContentWrapper>
  </Modal>);
};

EditModalWrapper.propTypes = {
  isOpen: bool,
  title: string.isRequired,
  subtitle: string,
  cancelButton: node.isRequired,
  saveButton: node.isRequired,
  isLarge: bool,
  children: node.isRequired
};

EditModalWrapper.defaultProps = {
  isOpen: false,
  subtitle: '',
  isLarge: false
};
