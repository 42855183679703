import {
  OpenSidebarButton,
  IconButton,
  ProfileHero,
  useWidth
} from '@paddl/storybook';
import {
  Metadata
} from '@paddl/utils-react';
import React, { useContext } from 'react';
import {
  getSkillCountByTimeline
} from '@paddl/utils-js';
import {
  arrayOf, number, shape, bool
} from 'prop-types';
import {
  Activities,
  EditProfileBannerModal,
  EditProfileInformationModal,
  EditProfileLinksModal,
  EditProfilePictureModal,
  EditProfileSummary,
  MenuItems,
  ShareProfileModal,
  Summary,
  TabContent
} from '../../components';
import { ModalContext } from './ModalContext';

const ModalManager = ({
  profile: {
    title,
    avatarUrl,
    bannerImageUrl,
    summary,
    fullName,
    urlSlug,
    id,
    extra: {
      location: profileLocation = {}
    } = {}
  }
}) => {
  return <>
  <EditProfileInformationModal
    data-test-id="profile-edit-modal"
    title={title}
    fullName={fullName}
    location={profileLocation}
  />
 <EditProfilePictureModal
   avatarUrl={avatarUrl}
 />
  <EditProfileBannerModal
    bannerImageUrl={bannerImageUrl}
  />
  <EditProfileSummary
    summary={summary}
  />
  <EditProfileLinksModal />
  <ShareProfileModal
    avatarUrl={avatarUrl}
    profilePath={urlSlug || id}
  />
         </>;
};

ModalManager.propTypes = {
  profile: shape({}).isRequired
};

export const ProfilePageContent = ({
  isProfileOwner,
  profile,
  totalXp,
  timeline
}) => {
  const width = useWidth();
  const totalSkills = timeline?.length
    ? getSkillCountByTimeline(timeline)
    : 0;

  const { setOpenModal } = useContext(ModalContext);

  const pageActionItems = [
    <IconButton
      onClick={() => setOpenModal('share-profile')}
      icon="share"
      variant="plained"
      size="small"
      color="grey"
      key="share"
    />,
    <OpenSidebarButton key="open-sidebar" />];

  if (isProfileOwner) {
    pageActionItems.unshift(<MenuItems
      key="edit-menu"
      data-test-id="profile-edit-menu"
      profile={profile}
    />);
  }

  return (<>
    <Metadata title="Profile | Paddl" />
      <ProfileHero
        onClick={isProfileOwner ? () => setOpenModal('edit-profile-picture') : () => {}}
        profileDetails={{
          fullName: profile?.fullName || '',
          profileTitle: profile?.title || '',
          avatarUrl: profile?.avatarUrl || '',
          location: profile?.extra?.location?.originalText || ''
        }}
        profileStats={{
          totalXp: totalXp || 0,
          totalActivities: timeline?.length || 0,
          totalSkills
        }}
        backgroundImage={profile?.bannerImageUrl || ''}
        width={width}
      />
    <TabContent
      summary={
        <Summary
          responseProfile={profile}
          totalXp={totalXp || 0}
          isProfileOwner={isProfileOwner}
          width={width}
          numberOfActivities={timeline?.length || 0}
        />
      }
      activities={
        <Activities
          timelineResponse={timeline}
          isTimelineFetched={!!timeline}
        />
      }
    />
    <ModalManager
      profile={profile}
    />
          </>
  );
};

ProfilePageContent.defaultProps = {
  timeline: undefined,
  totalXp: 0
};

ProfilePageContent.propTypes = {
  profile: shape({}).isRequired,
  timeline: arrayOf(shape({})),
  totalXp: number,
  isProfileOwner: bool.isRequired
};
