import React, { useState, useContext } from 'react';
import {
  func,
  string,
  shape
} from 'prop-types';
import { compose } from 'recompose';
import {
  getDecodedUserSessionCookie, trackEvent, trackIdentity, isToggleEnabled
} from '@paddl/utils-js';
import { TextField, Button } from '@paddl/storybook';
import { withDataSubmission, useAuthorisation } from '@paddl/utils-react';
import axios from 'axios';
import { EditModalWrapper } from '../EditModalWrapper';
import { ModalContext } from '../../pages/Profile/ModalContext';

export const EditProfileSummaryComp = ({
  summary: givenSummary,
  setProfileSummary: {
    submit: setProfileSummary
  }
}) => {
  const { profile_id: profileId } = getDecodedUserSessionCookie();
  const { openModal, closeModal } = useContext(ModalContext);
  const isOpen = openModal === 'edit-profile-summary';
  const { withAuthorisationHeaders } = useAuthorisation();

  const [summary, setSummary] = useState(givenSummary);

  const submitProfileSummaryData = () => {
    if (isToggleEnabled('neon')) {
      withAuthorisationHeaders((headers) => {
        axios.patch(`${process.env.API_PREFIX_AT_WORK}/my-profile`, { aboutMe: summary }, { headers });
      }).then(() => {
        trackEvent('Updated profile summary');
        trackIdentity(profileId, { summary });
        window.location.reload();
      });
    } else {
      setProfileSummary(`${process.env.API_PREFIX_V2}/profiles/${profileId}/summary`, { summary })
        .then(() => {
          trackEvent('Updated profile summary');
          trackIdentity(profileId, { summary });
          window.location.reload();
        });
    }
  };

  const renderCancelButton = () =>
    <Button
      onClick={closeModal}
      variant="contained"
      color="white"
      data-test-id="cancel-button"
    >
      Cancel
    </Button>;

  const renderSaveButton = () =>
    <Button
      onClick={() => submitProfileSummaryData()}
      variant="contained"
      data-test-id="confirm-button"
      disabled={givenSummary === summary}
    >
      Confirm
    </Button>;

  return (
    <EditModalWrapper
      isLarge
      data-test-id="modal-wrapper"
      hasBrandedColour={false}
      isOpen={isOpen}
      setIsOpen={closeModal}
      title="Edit About me"
      subtitle="Share more of your story, goals and aspirations."
      cancelButton={renderCancelButton()}
      saveButton={renderSaveButton()}
    >
      <TextField
        multiline
        isOutlined
        maxLength={1500}
        label="About me"
        data-test-id="about-me"
        placeholder="Share more of your story, goals and aspirations."
        onChange={(value) => setSummary(value)}
        helperText={`Max 1500${summary && summary.length  > 0 ? `/${summary.length}` : ''} Characters`}
        defaultValue={summary || ''}
      />
    </EditModalWrapper>
  );
};

EditProfileSummaryComp.propTypes = {
  summary: string,
  setProfileSummary: shape({
    submit: func.isRequired
  }).isRequired
};

EditProfileSummaryComp.defaultProps = {
  summary: ''
};

export const EditProfileSummary = compose(
  withDataSubmission({ propNameWrapper: 'setProfileSummary' })
)(EditProfileSummaryComp);
