import styled from '@emotion/styled';

export const ProfileLocationWrapper =  styled.div`
  .MuiAutocomplete-endAdornment {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;
