import React, { useState, useEffect, useCallback } from 'react';
import {
  TextField,
  AutocompleteSelect,
  Datepicker,
  useSnackbarContext
} from '@paddl/storybook';
import Button from '@mui/material/Button';
import {
  BottomInputsWrappers,
  InputWrapper,
  ButtonsWrapper,
  FieldWrapper
} from '../ProfileSettingsForms.styles';
import { useMyProfile } from '../../../pages/MyProfile/MyProfileContext';

const options = [
  {
    value: 'female',
    title: 'Female'
  },
  {
    value: 'male',
    title: 'Male'
  },
  {
    value: 'other',
    title: 'Other'
  }
];

const emptyForm = {
  fullName: '',
  dob: undefined,
  gender: ''
};

const toDisplayFormat = (storageFormat) => {
  if (!storageFormat) return null;
  const [yyyy, MM, dd] = storageFormat.split('-');

  return [dd, MM, yyyy].join('/');
};

const toStorageFormat = (displayFormat) => {
  if (!displayFormat) return undefined;
  const [dd, MM, yyyy] = displayFormat.split('/');

  return [yyyy, MM, dd].join('-');
};

export const NEON_PersonalDetailsForm = () => {
  const [formState, setFormState] = useState(emptyForm);

  const [errors, setErrors] = useState({});
  const { showSnackbar } = useSnackbarContext();
  const { profile, update, isLoading: isMyProfileLoading } = useMyProfile();

  const [isLoading, setIsLoading] = useState(true);

  const resetForm = useCallback(() => {
    const { fullName, dateOfBirth, gender } = profile;
    const dob = toDisplayFormat(dateOfBirth);
    setFormState({ fullName, dob, gender });
  }, [profile]);

  useEffect(() => {
    if (!isMyProfileLoading) {
      resetForm();
      setIsLoading(false);
    }
  }, [isMyProfileLoading, resetForm]);

  useEffect(() => {
    const _errors = {};
    if (!formState.fullName) _errors.fullName = true;
    setErrors(_errors);
  }, [formState]);

  const submitPersonalDetails =  async () => {
    try {
      const { fullName, dob, gender } = formState;
      await update({ fullName, gender, dateOfBirth: toStorageFormat(dob) });
      showSnackbar({ message: 'Personal details successfully updated.', type: 'success' });
    } catch (e) {
      console.error(e);
      showSnackbar({ message: 'Error updating personal details', type: 'error' });
    }
  };

  if (isLoading) return null;

  return (
        <>
          <FieldWrapper>
            <TextField
              required
              error={errors?.fullName || false}
              disabled={isMyProfileLoading}
              label="Full Name"
              placeholder="Full Name"
              isControlled
              value={formState.fullName}
              onChange={(fullName) =>
                setFormState({ ...formState, fullName }
                )}
            />
          </FieldWrapper>
          <BottomInputsWrappers>
            <InputWrapper>
              <FieldWrapper>
                <Datepicker
                  label="Date of birth"
                  placeholder="dd/mm/yyyy"
                  helperText="Not required to share."
                  action={(dob) => setFormState({ ...formState, dob })}
                  defaultValue={formState?.dob}
                />
              </FieldWrapper>
            </InputWrapper>
            <InputWrapper>
              <FieldWrapper>
                <AutocompleteSelect
                  label="Gender"
                  placeholder="Gender"
                  helperText="Not required to share."
                  action={({ value: gender }) => setFormState({ ...formState, gender })}
                  defaultValue={options.find(({ value }) => profile.gender === value)?.title}
                  options={options}
                />
              </FieldWrapper>
            </InputWrapper>
          </BottomInputsWrappers>
          <ButtonsWrapper>
            <Button
              data-test-id="cancel"
              variant="text"
              onClick={resetForm}
            >
              Cancel
            </Button>
            <Button
              data-test-id="submit"
              variant="contained"
              color="secondary"
              disabled={Object.keys(errors).length > 0 || isMyProfileLoading}
              onClick={submitPersonalDetails}
            >
              Save
            </Button>
          </ButtonsWrapper>
        </>
  );
};
