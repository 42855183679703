import styled from '@emotion/styled';
import { Colors } from '@paddl/storybook';

export const SummaryOuterContent = styled.div`
  background-color: ${({ profileIsEmpty }) => (profileIsEmpty ? Colors.grey_magnolia : Colors.white)};
  min-height: calc(100vh - 400px);
`;

export const SummaryWrapper = styled.div`
  display: ${({ breakpoint }) =>
    ((breakpoint !== 'xs' && breakpoint !== 'sm') && 'flex')};
  gap: 32px;
  max-width: 960px;
  box-sizing: border-box;
  padding:  36px 16px;
  margin: ${({ breakpoint }) => (breakpoint === 'xs' ? '0 auto' : '0 auto 16px auto')};

  .grid-item {
    > :not(:first-of-type) {
      margin-top: 40px;
    }
  }
`;

export const SummaryInnerContent = styled.div`
  background-color: ${({ profileIsEmpty }) => (profileIsEmpty ? 'transparent' : Colors.white)};
  width: 100%;
`;

export const EditableContentBlockWrapper = styled.div`
  margin-bottom: 12px;
`;

export const ExperienceGraphWrapper = styled.div`
  padding: ${({ isProfileOwner, breakpoint }) =>
    ((isProfileOwner && !(breakpoint === 'xs' || breakpoint === 'sm')) ? '20px 24px 24px' : '0')};
`;
