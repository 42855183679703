import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import {
  getDecodedUserSessionCookie,
  getCdnUrl,
  getFormData,
  trackIdentity,
  trackEvent
} from '@paddl/utils-js';
import {
  func,
  string,
  shape
} from 'prop-types';
import { compose } from 'recompose';
import {
  Button,
  FileSelect,
  Icons,
  ImageEditor,
  useWidth
} from '@paddl/storybook';
import { withDataSubmission } from '@paddl/utils-react';
import { EditModalWrapper } from '../EditModalWrapper';
import { SaveButton } from './SaveButton';
import {
  InnerWrapper,
  RemoveButton,
  BannersWrapper,
  ImageEditorWrapper,
  BannerPreview,
  Headline,
  DefaultBanner
} from './EditProfileBannerModal.styles';
import { ModalContext } from '../../pages/Profile/ModalContext';

const AddIcon = Icons.add;

const fileSelect = (setOriginalFile, setSelectedBannerId, setIsDirty) =>
  <FileSelect
    callback={(file) => {
      setOriginalFile(file);
      setSelectedBannerId(undefined);
      setIsDirty(true);
    }}
    isCallbackTypeBase64={false}
    acceptTypes="image/*"
    maxFileSize={10000000}
    maxFileNumber={1}
    isDisabled={false}
  >
    <InnerWrapper>
      <h2><AddIcon /> Select an Image</h2>
      <p>
        Recommended minimum size is 1400x2000px.
        <br />
        Maximum file size is 10mb.
      </p>
    </InnerWrapper>
  </FileSelect>;

const imageEditor = (setResizedFile, isDirty, originalFile, isLargeScreen) =>
  <ImageEditorWrapper>
    <ImageEditor
      data-test-id="image-editor"
      callback={(result) => setResizedFile(result)}
      disabled={!isDirty}
      imageUrl={originalFile}
      zoom={1}
      isAvatarStyle={false}
      height={140}
      width={isLargeScreen ? 656 : 432}
    />
  </ImageEditorWrapper>;

const cancelButton = (setIsDirty, setOriginalFile, setSelectedBannerId) =>
  <RemoveButton
    data-test-id="remove-button"
    role="button"
    onClick={
      () => {
        setIsDirty(false);
        setOriginalFile(undefined);
        setSelectedBannerId(undefined);
      }
    }
  >
    Remove
  </RemoveButton>;

const renderCancelButton = (onClick) =>
  <Button
    onClick={onClick}
    variant="contained"
    color="white"
    data-test-id="cancel-button"
  >
    Cancel
  </Button>;

export const EditProfileBannerModalComp = ({
  bannerImageUrl,
  getS3UploadToken: {
    submit: s3UploadTokenSubmit,
    response: s3UploadTokenResponse
  }
}) => {
  const width = useWidth();
  const { openModal, closeModal } = useContext(ModalContext);
  const isOpen = openModal === 'edit-banner';

  const [originalFile, setOriginalFile] = useState(bannerImageUrl);
  const [resizedFile, setResizedFile] = useState(undefined);
  const [bannerUrl, setBannerUrl] = useState(undefined);
  const [listOfBanners, setListOfBanners] = useState(undefined);
  const [selectedBannerId, setSelectedBannerId] = useState(undefined);
  const [isDirty, setIsDirty] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const { profile_id: profileId } = getDecodedUserSessionCookie();

  const fileReadUrl = process.env.FILE_READ_URL;

  const isLargeScreen = width !== 'xs' && width !== 'sm';

  const handleUploadImageToS3 = async (s3UploadToken = {}) => {
    const endpoint = `${process.env.FILE_UPLOAD_ENDPOINT}/`;
    const resizedImageData = getFormData(s3UploadToken, resizedFile?.blob || originalFile, 'banner');

    try {
      axios.post(endpoint, resizedImageData)
        .then((resizedImageResponse) => {
          setBannerUrl({ customBannerUrl: getCdnUrl(fileReadUrl, resizedImageResponse.data) });
        });
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  };

  useEffect(() => {
    const getBanners = async () => {
      const { id_token } = await getAccessTokenSilently({
        detailedResponse: true,
        timeoutInSeconds: 15
      });

      try {
        axios.get(
          `${process.env.API_PREFIX_V2}/banners`,
          {
            headers: {
              Authorization: `Bearer ${id_token}`,
              'X-profile-ID': profileId,
              'Use-Auth0': true
            }
          }
        ).then(({ data }) => {
          setListOfBanners(data?.listOfBanners);
        });
      } catch (err) {
        // TODO: display snackbar with ERROR message
        // eslint-disable-next-line
        console.error(err);
      }
    };

    getBanners();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  useEffect(() => {
    if (s3UploadTokenResponse && Object.keys(s3UploadTokenResponse).length) {
      handleUploadImageToS3(s3UploadTokenResponse);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [s3UploadTokenResponse]);

  useEffect(() => {
    if (listOfBanners && Object.keys(listOfBanners).length) {
      const lastItem = listOfBanners.slice(-1);
      const selectedId = lastItem[0].id;

      if (selectedId <= 6) setSelectedBannerId(selectedId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfBanners]);

  useEffect(() => {
    const uploadBanners = async () => {
      const { id_token } = await getAccessTokenSilently({ detailedResponse: true, timeoutInSeconds: 15 });
      // Save new banner image
      try {
        axios.post(
          `${process.env.API_PREFIX_V2}/banners/set-custom-banner`,
          bannerUrl,
          {
            headers: {
              Authorization: `Bearer ${id_token}`,
              'X-profile-ID': profileId,
              'Use-Auth0': true
            }
          }
        ).then(() => {
          trackEvent('Updated profile banner');
          trackIdentity(profileId, { profileBannerUrl: bannerUrl.customBannerUrl });

          window.location.reload();
          // TODO: display snackbar with SUCCESS message after page reload
        });
      } catch (err) {
        // TODO: display snackbar with ERROR message
        // eslint-disable-next-line
        console.error(err);
      }
    };

    if (bannerUrl) {
      uploadBanners();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bannerUrl,
    profileId,
    setBannerUrl
  ]);

  return (
    <EditModalWrapper
      data-test-id="modal-wrapper"
      hasBrandedColour={false}
      isOpen={isOpen}
      setIsOpen={closeModal}
      title="Edit Profile Banner"
      subtitle="Personalise your Profile with a banner image."
      cancelButton={renderCancelButton(closeModal)}
      saveButton={
        <SaveButton
          s3UploadTokenSubmit={s3UploadTokenSubmit}
          profileId={profileId}
          selectedBannerId={selectedBannerId}
          isDirty={isDirty}
        />
      }
      isLarge={width !== 'xs' && width !== 'sm'}
    >
      {!originalFile && fileSelect(setOriginalFile, setSelectedBannerId, setIsDirty)}
      {originalFile && !isDirty &&
      <>
        <DefaultBanner imgSourceUrl={originalFile} />
        {cancelButton(setIsDirty, setOriginalFile, setSelectedBannerId)}
      </>}
      {originalFile && isDirty &&
      <>
        {imageEditor(setResizedFile, isDirty, originalFile, isLargeScreen)}
        {cancelButton(setIsDirty, setOriginalFile, setSelectedBannerId)}
      </>}
      <Headline>... Or choose from Paddl artwork:</Headline>
      <BannersWrapper isLargeScreen={isLargeScreen}>
        {listOfBanners?.map(({ id, url }, index) => (
          // The banner endpoint always returns an array including 7 items.
          // The first 6 are the default banners, the 7th is the current banner in play.
          // Here is to display 6 thumbnails so we display index from 0 to 5.
          index < 6
            ? <BannerPreview
                key={id}
                imgSourceUrl={url}
                isLargeScreen={isLargeScreen}
                isSelected={id === selectedBannerId}
                bannerId={id}
                onClick={() => {
                  setOriginalFile(undefined);
                  setIsDirty(true);
                  setSelectedBannerId(id);
                }}
            />
            : null
        ))}
      </BannersWrapper>
    </EditModalWrapper>
  );
};

EditProfileBannerModalComp.propTypes = {
  bannerImageUrl: string.isRequired,
  getS3UploadToken: shape({
    submit: func.isRequired,
    response: shape({}).isRequired
  }).isRequired
};

export const EditProfileBannerModal = compose(
  withDataSubmission({ propNameWrapper: 'getS3UploadToken' })
)(EditProfileBannerModalComp);
