import React, { useEffect, useState, useContext } from 'react';
import {
  bool,
  func,
  shape,
  oneOfType,
  string
} from 'prop-types';
import { getDecodedUserSessionCookie, trackEvent, trackIdentity } from '@paddl/utils-js';
import { compose } from 'recompose';
import Grid from '@mui/material/Grid';
import { Button, TextField } from '@paddl/storybook';
import { withDataFetching, withDataSubmission } from '@paddl/utils-react';
import { EditModalWrapper } from '../EditModalWrapper';
import { ModalContext } from '../../pages/Profile/ModalContext';

const INITIAL_FORM_DATA = {
  instagramId: '',
  facebookId: '',
  linkedinId: '',
  twitterId: '',
  behanceId: '',
  dribbbleId: '',
  githubId: '',
  websiteLink: ''
};

const isValidUrl = (redirectUri) => {
  if (redirectUri === '') { return true; }

  try {
    // eslint-disable-next-line no-new
    new URL(redirectUri);

    return true;
  } catch {
    return false;
  }
};

export const EditProfileLinksModalComp = ({
  setProfileLinks: {
    submit: submitProfileLinks
  },
  getProfileLinks: {
    fetch: fetchProfileLinks,
    isSuccess: fetchProfileLinksSuccess,
    response: fetchProfileLinksResponse

  }
}) => {
  const { profile_id: profileId } = getDecodedUserSessionCookie();
  const [profileLinksFormState, setProfileLinksFormState] = useState(
    INITIAL_FORM_DATA
  );

  const { openModal, closeModal } = useContext(ModalContext);
  const isOpen = openModal === 'edit-profile-links';

  useEffect(() => {
    if (profileId) {
      fetchProfileLinks(`${process.env.API_PREFIX_V2}/profile-links/${profileId}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  useEffect(() => {
    if (fetchProfileLinksSuccess) {
      setProfileLinksFormState({ ...INITIAL_FORM_DATA, ...fetchProfileLinksResponse });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchProfileLinksSuccess]);

  const isWebsiteLinkValid = isValidUrl(profileLinksFormState.websiteLink);

  const submitProfileData = () => {
    if (!isWebsiteLinkValid) { return false; }

    submitProfileLinks(`${process.env.API_PREFIX_V2}/profile-links`, profileLinksFormState)
      .then(() => {
        trackEvent('Updated profile links');
        trackIdentity(profileId, profileLinksFormState);
        window.location.reload();
      });

    return true;
  };

  const isPristine = (JSON.stringify(fetchProfileLinksResponse) === JSON.stringify(profileLinksFormState));

  const renderCancelButton = () => (
    <Button
      onClick={closeModal}
      variant="contained"
      color="white"
      data-test-id="cancel-button"
    >
      Cancel
    </Button>
  );

  const renderSaveButton = () => (
    <Button
      onClick={() => submitProfileData()}
      variant="contained"
      data-test-id="confirm-button"
      disabled={isPristine || !isWebsiteLinkValid}
    >
      Save
    </Button>
  );

  return (
    <EditModalWrapper
      data-test-id="modal-wrapper"
      hasBrandedColour={false}
      isLarge
      isOpen={isOpen}
      setIsOpen={closeModal}
      title="Edit Links"
      subtitle="Share links to your other profiles."
      cancelButton={renderCancelButton()}
      saveButton={renderSaveButton()}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Facebook"
            data-test-id="facebook"
            startAdornment="facebook.com/"
            placeholder="my-id"
            defaultValue={profileLinksFormState.facebookId}
            onChange={(facebookId) => {
              setProfileLinksFormState({
                ...profileLinksFormState,
                facebookId
              });
            }}
          />
          <TextField
            label="Linkedin"
            data-test-id="linkedin"
            startAdornment="linkedin.com/in/"
            placeholder="my-id"
            defaultValue={profileLinksFormState.linkedinId}
            onChange={(linkedinId) => {
              setProfileLinksFormState({
                ...profileLinksFormState,
                linkedinId
              });
            }}
          />
          <TextField
            label="Twitter"
            data-test-id="twitter"
            startAdornment="twitter.com/"
            placeholder="my-id"
            defaultValue={profileLinksFormState.twitterId}
            onChange={(twitterId) => {
              setProfileLinksFormState({
                ...profileLinksFormState,
                twitterId
              });
            }}
          />
          <TextField
            label="Instagram"
            data-test-id="instagram"
            startAdornment="instagram.com/"
            placeholder="my-id"
            defaultValue={profileLinksFormState.instagramId}
            onChange={(instagramId) => {
              setProfileLinksFormState({
                ...profileLinksFormState,
                instagramId
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Behance"
            data-test-id="behance"
            startAdornment="behance.net/"
            placeholder="my-id"
            defaultValue={profileLinksFormState.behanceId}
            onChange={(behanceId) => {
              setProfileLinksFormState({
                ...profileLinksFormState,
                behanceId
              });
            }}
          />
          <TextField
            label="Dribbble"
            data-test-id="dribbble"
            startAdornment="dribbble.com/"
            placeholder="my-id"
            defaultValue={profileLinksFormState.dribbbleId}
            onChange={(dribbbleId) => {
              setProfileLinksFormState({
                ...profileLinksFormState,
                dribbbleId

              });
            }}
          />
          <TextField
            label="Github"
            data-test-id="github"
            startAdornment="github.com/"
            placeholder="my-id"
            defaultValue={profileLinksFormState.githubId}
            onChange={(githubId) => {
              setProfileLinksFormState({
                ...profileLinksFormState,
                githubId
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Website"
            data-test-id="website-link"
            placeholder="https://www.yoursitehere.com"
            hasError={!isWebsiteLinkValid}
            errorText="Link must be a valid URL"
            defaultValue={profileLinksFormState.websiteLink}
            onChange={(websiteLink) => {
              setProfileLinksFormState({
                ...profileLinksFormState,
                websiteLink
              });
            }}
          />
        </Grid>
      </Grid>
    </EditModalWrapper>
  );
};

EditProfileLinksModalComp.propTypes = {
  setProfileLinks: shape({
    submit: func.isRequired
  }).isRequired,
  getProfileLinks: shape({
    fetch: func.isRequired,
    isSuccess: bool,
    response: oneOfType([string, shape({})])
  }).isRequired
};

EditProfileLinksModalComp.defaultProps = {};

export const EditProfileLinksModal = compose(
  withDataSubmission({ propNameWrapper: 'setProfileLinks' }),
  withDataFetching({ propNameWrapper: 'getProfileLinks' })
)(EditProfileLinksModalComp);
