import { shape, string } from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useResource, MemberAppNavigation_Populated_NEON } from '@paddl/utils-react';
import { ModularPage } from '@paddl/storybook';
import { ModalContext } from './ModalContext';
import { NEON_ProfilePageContent } from './NEON_ProfilePageContent';
import {
  ProfileSuggestions
} from '../../components';
import { ShareProfileActionButton } from './ShareProfileActionButton';

export const NEON_Profile = ({
  match: {
    params: {
      profileId: profileIdOrSlug
    }
  }
}) => {
  const { data: statsData, isLoading: isStatsLoading } = useResource(`${process.env.API_PREFIX_AT_WORK}/public/profile/${profileIdOrSlug}/stats`);

  const [openModal, setOpenModal] = useState(null);

  const modalContext = useMemo(() => {
    return {
      openModal, setOpenModal, closeModal: () => setOpenModal(null)
    };
  }, [openModal, setOpenModal]);

  const pageActionItems = [
    <ShareProfileActionButton />
  ];

  return (
    <ModalContext.Provider value={modalContext}>
      <ModularPage
        sidebarContent={isStatsLoading ? null : <ProfileSuggestions
          profileIdOrSlug={profileIdOrSlug}
          totalSkills={statsData.stats.skillsCount}
        />}
        pageName="Profile"
        pageContent={<NEON_ProfilePageContent
          profileIdOrSlug={profileIdOrSlug}
        />}
        pageActionItems={pageActionItems}
        navigationContent={<MemberAppNavigation_Populated_NEON currentPage="profile" />}
      />
    </ModalContext.Provider>
  );
};

NEON_Profile.propTypes = {
  match: shape({
    params: shape({
      profileId: string
    }).isRequired
  }).isRequired
};
